import React from "react";
import { images } from "../Assets";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const AboutUs = () => {
  return (
    <div className=' bg-white '>
      <NavBar />
      <div className='pt-[150px]'>
        {/* <img src={images.aboutback} alt='aboutus' className='w' /> */}
      </div>
      <div className='w-[90vw] md:w-[60%] text-center mx-auto '>
        <h1 className=' font-bold text-2xl md:text-5xl text-primaryBlack'>
          Hello <span className='text-[#C3990F]'>There!</span>
        </h1>
        <p className='text-primaryBlack text-xl mx-4 my-4 font-semibold'>
          We’ve created a Dispatch model to run your Errands, ensuring a helper
          is exactly where you want them to be.
        </p>

        <p className='text-base  font-normal  text-primaryBlack leading-7'>
          At NowNow Dispatch, we believe you work really hard and deserve to
          catch a break. That’s why we’ve built a platform that enable you
          outsource your errands, so you can focus on the more important tasks.
          We buy your Food, do your Market & Drug Runs, and deliver them in the
          shortest time.
        </p>
      </div>
      <div className='md:flex items-center bg-white  justify-between px-5 pb-4 md:px-20'>
        <div className='gap-4'>
          <div className='flex justify-center h-[500px]'>
            <img src={images.aboutpix} className='' alt='aboutus' />
          </div>
        </div>
        <div className='gap-10 w-full md:w-[38rem]'>
          <p className=' mb-10'>
            Our team is made up of Soft life enthusiasts, techies, and logistics
            experts who are passionate about creating an exceptional experience
            for you. We work tirelessly to ensure that every errand is run
            swiftly, and every interaction is friendly and professional.
          </p>

          <div className='flex gap-5'>
            <div className='h-[50px] w-[50px]'>
              <img src={images.mispix} className=' ' alt='aboutus' />
            </div>
            <div>
              <p className='text-2xl font-semibold text-primaryBlack'>
                Our Mission
              </p>
              <p className='font-normal text-primaryBlack'>
                Our mission is to connect you to the things you love and the
                services you need.
              </p>
            </div>
          </div>

          <div className='flex gap-5 mt-5'>
            <div className=''>
              <img
                src={images.vispix}
                className='h-[60px] w-[60px]'
                alt='aboutus'
              />
            </div>
            <div>
              <p className='text-2xl  font-semibold'>Our Vision</p>
              <p className=' font-normal text-primaryBlack '>
                Enabling everyone become 10x more productive by having 50% of
                the to-do list outsourced or delivered.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='text-center md:ml-20 mb-10'>
        <p className='font-bold'>Contact Us:</p>
        <p className='font-semibold text-primaryBlack mt-5 leading-tight'>
          No. 64 Ndidem Usang Iso road, Calabar.
        </p>
        <a
          href='tel:+234 9027277509'
          className=' font-semibold text-primaryBlack block mt-5 md:mt-5'
        >
          +234 9027277509
        </a>
        <a
          href='mailto:support@nownowdispatch.com'
          className=' font-semibold text-primaryBlack block mt-5 md:mt-5'
        >
          support@nownowdispatch.com
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
