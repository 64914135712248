import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const TermsService = () => {
  return (
    <div className='h-full'>
      <NavBar />
      <div className='p-4 md:p-32 mx-auto pt-32'>
        <div>
          <h1 className='text-left font-bold text-5xl text-primaryBlack'>
            Terms of <span className='text-[#C3990F]'>Service</span>
          </h1>
          <p className='text-2xl font-semibold text-primaryBlack mt-5'>
            Last Updated: June 20, 2024
          </p>
        </div>
        <div className='mt-16 text-base font-normal text-primaryBlack space-y-6'>
          <p>
            These Terms and Conditions (“Terms”) govern your use of the Now-Now
            Dispatch services (“The Services”), including our website, content,
            mobile applications (or portions thereof) or other services that are
            made available by NOW NOW DISPATCH, a company established in
            Nigeria, having its offices at No. 27 Spring Road, Calabar, Cross
            River State, Nigeria, registered at the Corporate Affairs Commission
            with registration number 2646721 (“Now Now Dispatch”).
          </p>
          <p>
            By using the Services, you agree to be bound by these Terms and
            acknowledge our Privacy Policy, which explains how we collect, use,
            and share information.
          </p>
          <p>
            The Services comprise a platform that presents you the opportunity
            which you can engage to send your products, goods, packages or items
            through our Associates from your address, store, location, office or
            warehouse to any location within our coverage areas in Nigeria. In
            some cases, picking, packing or delivery services may be performed
            by third parties including a retailer, a Partner or third-party
            logistics provider (collectively, “Third Party Providers”).
          </p>
          <p>
            When you use the Services, you authorize the delivery of those
            products, goods, packages or items from and to any point you select.
            Unless otherwise specified, you acknowledge and agree that Now Now
            Dispatch is acting as your agent in picking, packing and/or
            delivering goods owned by you or in your possession to your
            indicated persons/locations.
          </p>
          <p>
            You also acknowledge and agree that, except as expressly provided
            for otherwise in these Terms or a separate agreement between you and
            Now Now Dispatch, Now Now Dispatch does not form any employment or
            agency relationship with you and does not hold title to any
            products, goods, packages or items, that you indicated through the
            Services.
          </p>

          <h2 className='text-xl text-redOuter font-semibold mt-10'>
            1. YOUR USE OF THE SERVICES
          </h2>
          <p>
            Now Now Dispatch grants you a limited, non-exclusive,
            non-transferable, non-sublicensable and revocable license to use the
            Services for their intended purposes subject to your compliance with
            these Terms and our policies. You may not copy, modify, distribute,
            sell, or lease any part of the Services. Unless such restriction is
            prohibited by law or you have our written permission, you may not
            reverse engineer or attempt to extract the source code of the
            Services. You may only access the Services through the interfaces
            that we provide for that purpose (for example, you may not “scrape”
            the Services through automated means or “frame” any part of our
            Services), and you may not interfere or attempt to disrupt our
            Services.
          </p>
          <p>
            Some parts of our Services may allow you to upload or submit content
            (such as text, images, video, lists, links, and other materials).
            You retain all rights in any content that you upload or submit, and
            are solely responsible for that content. You grant Now Now Dispatch
            a non-exclusive, royalty-free, worldwide, transferable,
            sub-licensable license to use, store, publicly display, publicly
            perform, reproduce, modify, create derivative works from, and
            distribute any such content for the purposes of operating,
            providing, and improving the Services. Now Now Dispatch may, in its
            sole discretion, remove or take down any content that you upload or
            submit to the Services for any reason, including violation of these
            Terms or any other policies.
          </p>
          <p>
            You may have the option of accessing the Services through
            downloadable software and this software may update itself
            automatically on your device. Some software or portions of software,
            in our Services, may be governed by open-source licenses. In that
            case, we will make such licenses available to you and, in the case
            of conflict between such a license and these Terms, the open source
            license will control but only with respect to the software, or
            portion of the software, to which it applies.
          </p>
          <p>
            If you are using Now Now Dispatch on behalf of a business or other
            entity, you represent and warrant that you have the necessary
            authority to bind that business or entity to these Terms and that
            you are agreeing to these Terms on behalf of that business or
            entity.
          </p>
          <p>
            In order to use the Services, you may need to create a register as a
            user. You agree that you are responsible for all conduct and
            transactions that take place on your account and that you will take
            precautions to keep your password and other account information
            secure. Now Now Dispatch reserves the right to decline orders,
            refuse partial or full delivery, terminate your registration, and/or
            cancel your service request at any time at its sole discretion.
          </p>
          <p>
            We are constantly modifying and improving our Services. We may
            introduce new features, change existing features, or remove features
            from the Services at any time and without notice. If you provide us
            with any feedback or comments regarding the Services, you grant Now
            Now Dispatch the right to use such feedback or comments for any
            purpose without restriction or payment to you or any agent.
          </p>

          <h2 className='text-xl text-redOuter font-semibold mt-10'>
            2. NOW NOW DISPATCH COMMUNICATIONS
          </h2>
          <p>
            By registering as a Now Now Dispatch user on our website and
            application, you agree to accept and receive communications from us,
            including via e-mail, text messages, calls, and push notifications
            to the cellular telephone number and email you provided to us.
          </p>
          <p>
            You understand and agree that you may receive communications
            generated by automatic telephone dialling systems and/or which will
            deliver prerecorded messages sent by or on behalf of NowNow
            Dispatch, its affiliated companies and/or our partners, including
            but not limited to communications concerning your service request
            through your registration on the Now Now Dispatch platform. If you
            do not wish to receive promotional emails, text messages, or other
            communications, you may opt out of such communications at any time
            by sending an email to support@nownowdispatch.com.
          </p>

          <h2 className='text-xl text-redOuter font-semibold mt-10'>
            3. THIRD-PARTY PRODUCTS AND CONTENT
          </h2>
          <p>
            You agree that Now Now Dispatch does not assume responsibility for
            any products, content, services, websites, advertisements, offers,
            or information that is provided by third parties and made available
            through the Services. If you request our Services, use or access any
            such products, content, services, advertisements, offers, or
            information through the Services, you agree that you do so at your
            own risk and that Now Now Dispatch will have no liability based on
            such engagement, use or access.
          </p>

          <h2 className='text-xl text-redOuter font-semibold mt-10'>
            4. SERVICE PROVIDED AS-IS AND RELEASE OF CLAIMS
          </h2>
          <p>
            The services are provided “as is” and “as available” to the maximum
            extent permitted by applicable laws. Now Now Dispatch disclaims all
            representations and warranties, express, implied, or statutory,
            including the implied warranties of merchantability, fitness for a
            particular purpose, and non-infringement. In addition, Now Now
            Dispatch makes no representation, warranty, or guarantee regarding
            the reliability, timeliness, quality, suitability, or availability
            of the services, any services provided by Independent Partners or
            third-party providers, or that the services will be uninterrupted or
            error-free, Now Now Dispatch does not guarantee the quality,
            suitability, safety or ability of user, third party providers, or
            retailers. You agree that the entire risk arising out of your use of
            the services, any services provided by independent partners or
            third-party providers, or any service requested by you or delivered
            to you, remains solely with you.
          </p>
          <p>
            You agree that neither Now Now Dispatch nor its affiliates, retail
            partners, independent partners, licensors, or suppliers is
            responsible for the fitness or conduct of any Now Now Dispatch
            associates nor its affiliates, retail partners, independent partner
            will be liable for any claim, injury or damage arising in connection
            with the acts or omissions of any third party provider.
          </p>
          <p>
            If you have a dispute with any of Now Now Dispatch Associates or its
            Independent partners or Third Party Providers, you agree to release
            Now Now Dispatch (including our affiliates, and each of our
            respective officers, directors, employees, agents, shareholders,
            retail partners and licensors) from any claims, demands and damages
            of every kind and nature, known and unknown, suspected and
            unsuspected, disclosed and undisclosed, arising out of or in any way
            connected to such disputes.
          </p>

          <h2 className='text-xl text-redOuter font-semibold mt-10'>
            5. LIMITATION OF LIABILITY
          </h2>
          <p>
            In no event shall Now Now Dispatch (including our affiliates, and
            each of our respective officers, directors, employees, agents,
            shareholders, retail partners, licensors, and suppliers) be liable
            to you for any incidental, special, punitive, consequential, or
            indirect damages (including, but not limited to, damages for
            deletion, corruption, loss of data, loss of programs, failure to
            store any information or other content maintained or transmitted by
            the services, service interruptions, or for the cost of procurement
            of substitute services) arising out of or in connection with the
            services, or these terms, however arising including negligence, even
            if we or our agents or representatives know or have been advised of
            the possibility of such damages. In no event shall Now Now Dispatch
            (including our affiliates, and each of our respective officers,
            directors, employees, agents, shareholders, retail partners,
            licensors, and suppliers) be liable for any indirect, special,
            punitive, incidental, exemplary and/or consequential damages
            (including, but not limited to physical damages, bodily injury,
            death and/or emotional distress and discomfort) arising out of your
            use of the services, any services provided by personal shoppers or
            third-party providers, or any products requested by you or delivered
            to you, even if we or our agents or representatives know or have
            been advised of the possibility of such damages. This provision
            applies to the maximum extent permitted by applicable law.
          </p>

          <h2 className='text-xl text-redOuter font-semibold mt-10'>
            6. INDEMNIFICATION
          </h2>
          <p>
            You agree to defend, indemnify and hold harmless Now Now Dispatch
            and its officers, directors, employees, agents, shareholders,
            affiliates, and retail partners (each, an “Indemnified Party”) from
            and against any losses, claims, actions, costs, damages, penalties,
            fines and expenses, including without limitation attorneys’ and
            experts’ fees and expenses, that may be incurred by an Indemnified
            Party arising out of, relating to or resulting from your
            unauthorised use of the Services or any breach by you of these
            Terms, including without limitation any actual or alleged violation
            of any law, rule or regulation
          </p>

          <h2 className='text-xl text-redOuter font-semibold mt-10'>
            7. DISPUTES & ARBITRATION
          </h2>
          <p>
            In case of any dispute with Now Now Dispatch arising out of your use
            of the Services, you agree to contact us first and attempt to work
            out any such dispute amicably. Mandatory Arbitration: If we are
            unable to work out a solution amicably within 21 days, such dispute
            shall be referred to arbitration in accordance with the Arbitration
            and Conciliation Act 2004.
          </p>
          <p>
            The disputing Parties shall nominate a sole Arbitrator to resolve
            the dispute and in the event that the Parties are unable to agree on
            the choice of the Arbitrator within fourteen (14) days, then either
            Party shall be entitled to refer the matter to the Chartered
            Institute of Arbitrators of Nigeria. Class action waiver: to the
            extent permissible by law, all claims must be brought in the
            parties’ individual capacity, and not as a class member in any
            purported class, collective action, or non-paga representative
            proceeding (collectively “class action waiver”). The arbitrator may
            not consolidate more than one person’s claims or engage in any class
            arbitration. You agree that, by entering into these terms, you and
            Now Now Dispatch are each waiving the right to participate in a
            class action. In any lawsuit in which (1) the complaint is filed as
            a class action, collective action or non-PAGA representative action;
            and (2) the civil court of competent jurisdiction in which the
            complaint was filed finds the Class Action Waiver is unenforceable
            (and such finding is confirmed by appellate review if review is
            sought), the Class Action Waiver shall be severable from this
            Agreement and in such instances, the class action, collective action
            and/or non-PAGA representative action must be litigated in a civil
            court of competent jurisdiction and not as a class, collective or
            non-PAGA representative arbitration.
            <p>
              Paga waiver: to the extent permissible by law, there will be no
              right or authority for any dispute to be brought, heard, or
              arbitrated on a group basis or in any action in which a party
              seeks to represent other individuals in a private attorney general
              action (“paga waiver”). Paga claims may only be arbitrated on an
              individual basis.In any lawsuit in which (1) the complaint is
              filed as a private attorney general action seeking to represent
              any individual(s) other than the named plaintiff; and (2) the
              civil court of competent jurisdiction in which the complaint was
              filed finds the PAGA Waiver is unenforceable (and such finding is
              confirmed by appellate review if the review is sought), the PAGA
              Waiver shall be severable from this Agreement and in such
              instances, the private attorney general action must be litigated
              in a civil court of competent jurisdiction and not as a private
              attorney general arbitration. Notwithstanding any other clause
              contained in this Agreement, any claim that all or part of the
              Class Action Waiver or PAGA Waiver is invalid, unenforceable,
              unconscionable, void or voidable may be determined only by a court
              of competent jurisdiction and not by an arbitrator. The Class
              Action Waiver and PAGA Waiver shall be severable when a dispute is
              filed as an individual action and severance is necessary to ensure
              that the individual action proceeds in arbitration.
            </p>
          </p>

          <h2 className='text-xl text-redOuter font-semibold mt-10'>
            8.TERMINATION
          </h2>
          <p>
            You can stop using the Now Now Dispatch Services at any time and
            without notice to us. Similarly, we may terminate access to the
            Services to you or any other users or stop offering the Service at
            any time without notice.
          </p>
          <p>
            Third-party service providers soliciting Now Now Dispatch customers
            as your own customers is strictly prohibited and will lead to the
            termination of this contractual relationship.
          </p>

          <h2 className='text-xl text-redOuter font-semibold mt-10'>
            9. CONTROLLING LAW
          </h2>
          <p>
            The construction and execution of these Terms and any dispute, of
            whatsoever nature arising in connection with this Agreement or
            performance under it, including any remedy thereof, shall be
            governed exclusively by the laws of the Federal Republic of Nigeria.
            Any claims arising out of or relating to these Terms or use of the
            Services that are not subject to Section 7 (Disputes & Arbitration)
            of these Terms shall be brought exclusively to the State courts of
            Calabar, Cross-river State, Nigeria, and you and Now Now Dispatch
            consent to the personal jurisdiction of those courts..
          </p>

          <h2 className='text-xl text-redOuter font-semibold mt-10'>
            9. ENTIRE AGREEMENT & SEVERABILITY
          </h2>
          <p>
            These Terms, subject to any amendments, modifications, or additional
            agreements you may enter into with Now Now Dispatch, shall
            constitute the entire agreement between you and Now Now Dispatch
            with respect to the Services and any use of the Services. If any
            provision of these Terms is found to be invalid by a court of
            competent jurisdiction, that provision only will be limited to the
            minimum extent necessary and the remaining provisions will remain in
            full force and effect.
          </p>
          <p>
            We may modify these Terms at any time. If we make changes to these
            Terms that materially affect your use of the Services, we will
            notify you by posting a notice on our website or app or by otherwise
            notifying you. Your continued use of the Services will constitute
            your acceptance of the changes.
          </p>
          <h2 className='text-xl text-redOuter font-semibold mt-10'>
            9. ASSIGNMENT
          </h2>
          <p>
            You may not assign any of your rights, licenses, or obligations
            under these Terms. Any such attempt at assignment by you shall be
            void. Now Now Dispatch may assign its rights, licenses, and
            obligations under these Terms without limitation
          </p>
          <h2 className='text-xl text-redOuter font-semibold mt-10'>
            9. NO WAIVER
          </h2>
          <p>
            Now Now Dispatch’s failure to monitor or enforce a provision of
            these Terms does not constitute a waiver of its right to do so in
            the future with respect to that provision, any other provision, or
            these Terms as a whole
          </p>
          <h2 className='text-xl text-redOuter font-semibold mt-10'>
            9. CHANGES TO THE TERMS
          </h2>
          <p>
            We may make changes to these Terms from time to time. When we do so,
            we will post the most current version of the Terms on our website
            and, if a revision to the Terms is material, we will notify you of
            the new Terms by email or a notification on our Services. Changes to
            these terms will not apply retroactively. If you do not agree to the
            modified terms, you should discontinue your use of the Services.
          </p>
          <h2 className='text-xl text-redOuter font-semibold mt-10'>
            9. COPYRIGHT POLICY
          </h2>
          <p>
            Now Now Dispatch respects the intellectual property rights of others
            and has implemented a copyright policy in accordance with the
            Nigeria Copyright Act and other relevant laws. Now Now Dispatch will
            respond to valid notices of copyright infringement and reserves the
            right to terminate any users, at our sole discretion and without
            notice, who repeatedly infringe copyrights or other intellectual
            property rights of others.
            <p>
              If you believe any content posted or made available on the Now Now
              Dispatch Services constitutes an infringement of your copyright
              rights, you may send a written notice of infringement to the Now
              Now Dispatch Designated Agents using the contact information
              listed below. In your notice, please specify the nature of the
              copyright infringement with relevant information. (e.g., the URL
              of the claimed infringing material if applicable or other means by
              which we may locate the material)
            </p>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsService;
