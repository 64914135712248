import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { images } from "../Assets";
import HamburgerIcon from "../Assets/hamburgerIcon";
import { PiTiktokLogo } from "react-icons/pi";

const NavLinks = [
  {
    id: 1,
    name: "Partner with Us",
    link: "/partner-rider",
  },
  {
    id: 2,
    name: "About Us",
    link: "/about-us",
  },
  {
    id: 3,
    name: "Contact Us",
    link: "/contact-us",
  },
];

const NavBar = () => {
  const location = useLocation();
  const [nav, setNav] = useState(false);

  const pathname = location.pathname.split("/").filter((segment) => segment)[0];
  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className='bg-[#FAFFF8] px-10 shadow-sm fixed left-0 right-0 z-50 w-full flex items-center justify-between p-4'>
      <Link to='/'>
        <img src={images.nowpix} className='h-14 w-auto' alt='nownow rider' />
      </Link>

      <div className='hidden md:flex items-center justify-center gap-12'>
        {NavLinks.map(({ id, name, link }) => (
          <li key={id} className='list-none'>
            <Link
              to={link}
              className={
                link === `/${pathname}`
                  ? "border-primaryGreen border-b-4 text-primaryBlack font-bold pb-2 rounded-md"
                  : "border-transparent text-[#667499]  hover:text-primaryBlack pb-2"
              }
              aria-current={link === pathname ? "page" : undefined}
            >
              {name}
            </Link>
          </li>
        ))}
      </div>

      <div className='hidden md:flex items-center justify-center space-x-8'>
        <li className='list-none'>
          <Link to='https://www.instagram.com/nownowdispatch'>
            <img
              src={images.instagramLogo}
              className='w-6 h-6'
              alt='instagram'
            />
          </Link>
        </li>
        <li className='list-none'>
          <Link to='https://www.twitter.com/nownowdispatch'>
            <img src={images.twitter} className='w-6 h-6' alt='twitter' />
          </Link>
        </li>
        <li className='list-none'>
          <Link to='https://www.facebook.com/share/Mt28BKXFUZo7UmDn/?mibextid=LQQJ4d'>
            <img src={images.facebook} className='w-6 h-6' alt='facebook' />
          </Link>
        </li>
        <li className='list-none'>
          <Link to='https://www.linkedin.com/company/nownow-dispatch/'>
            <img src={images.linkedin} className='w-6 h-6' alt='linkedin' />
          </Link>
        </li>
        <li className='list-none'>
          <Link to='https://www.tiktok.com/@nownowdispatch?_t=8nOJAdJ7iTt&_r=1'>
            <PiTiktokLogo
              className='w-6 h-6 rounded-lg bg-[#6BA740] text-white'
              alt='tiktok'
            />
          </Link>
        </li>
      </div>

      <div onClick={handleNav} className='block md:hidden cursor-pointer'>
        {nav ? (
          <img src={images.closeBtn} className='w-6 h-6' alt='close' />
        ) : (
          <HamburgerIcon size={40} />
        )}
      </div>

      {/* Mobile Navigation Menu */}
      <div
        className={
          nav
            ? "fixed h-full left-0 top-0 w-[75%] bg-[#FAFFF8] z-50 flex flex-col items-center border-r-2 border-gray-300 duration-500 ease-in-out"
            : "fixed left-[-100%] top-0 h-full bg-[#FAFFF8] z-50 flex flex-col items-center ease-in-out duration-500"
        }
      >
        <div className='mt-10 ml-20'>
          <Link to={"/"}>
            <img
              src={images.nowpix}
              className='h-16 w-auto'
              alt='nownow rider'
            />
          </Link>
        </div>
        <ul className='flex flex-col items-center justify-center gap-8 p-8'>
          {NavLinks.map(({ id, name, link }) => (
            <li key={id} className='list-none'>
              <Link
                to={link}
                className={
                  link === `/${pathname}`
                    ? "border-primaryGreen border-b-4 text-primaryBlack text-xl font-bold pb-2 rounded-md"
                    : "border-transparent text-[#667499] text-lg hover:text-[text-[#667499]/90 hover:border-[#667499]/90 pb-2"
                }
                aria-current={link === pathname ? "page" : undefined}
              >
                {name}
              </Link>
            </li>
          ))}
        </ul>

        <ul className='flex items-center justify-center gap-8 mt-5'>
          <li className='list-none'>
            <Link to='https://www.instagram.com/nownowdispatch'>
              <img
                src={images.instagramLogo}
                className='w-8 h-8'
                alt='instagram'
              />
            </Link>
          </li>
          <li>
            <Link to='https://www.twitter.com/nownowdispatch'>
              <img src={images.twitter} className='w-8 h-8' alt='twitter' />
            </Link>
          </li>
          <li>
            <Link to='https://www.facebook.com/share/Mt28BKXFUZo7UmDn/?mibextid=LQQJ4d'>
              <img src={images.facebook} className='w-8 h-8' alt='facebook' />
            </Link>
          </li>
          <li>
            <Link to='https://www.linkedin.com/company/nownow-dispatch/'>
              <img src={images.linkedin} className='w-8 h-8' alt='linkedin' />
            </Link>
          </li>
          <li>
            <Link to='https://www.tiktok.com/@nownowdispatch?_t=8nOJAdJ7iTt&_r=1'>
              <PiTiktokLogo
                className='w-6 h-6 rounded-lg bg-[#6BA740] text-white'
                alt='tiktok'
              />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
