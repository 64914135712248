import React from "react";
import { images } from "../Assets";

const StoreButtons = () => {
  return (
    <div className='flex flex-col md:flex-row  gap-2 md:gap-8'>
      <a
        href='https://play.google.com/store/apps/details?id=com.nownowdispatch'
        target='_blank'
        rel='noopener noreferrer'
      >
        <div className='flex items-center gap-2 justify-center bg-primaryBlack rounded-lg p-2'>
          <img
            src={images.playstore}
            alt='Google Play Store'
            className='w-8 h-8 md:w-6 md:h-6 mr-1'
          />
          <div>
            <p className='text-xs md:text-xs font-normal text-[#FFFFFF]'>
              Get it on
            </p>
            <p className=' text-xs md:text-sm font-bold text-[#FFFFFF]'>
              Google Play
            </p>
          </div>
        </div>
      </a>

      {/* <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
          <div className='flex items-center gap-3  justify-center bg-primaryBlack rounded-lg px-5 py-2'>
            <img src={images.apple} alt="Apple Store" className="w-3 h-3" />
            <div>
              <p className='text-[0.5rem] md:text-xs font-normal text-[#FFFFFF]'>Download from</p>
              <span className='text-[0.6rem] md:text-sm font-bold text-[#FFFFFF]'>Apple Store</span>
            </div>
          </div>
      </a> */}
    </div>
  );
};

export default StoreButtons;
