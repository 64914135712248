import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import "../styles.css";

const PrivacyPolicy = () => {
  return (
    <div className='h-[100vh]'>
      <NavBar />
      <div className='p-4 md:p-32 mx-auto pt-32'>
        <div>
          <h1 className='text-left font-bold text-5xl text-primaryBlack'>
            Privacy <span className='text-[#C3990F]'>Policy</span>
          </h1>
          <p className='text-2xl font-semibold text-primaryBlack mt-5'>
            Last Updated: June 20, 2024
          </p>
        </div>
        <div className='mt-10 text-base font-normal text-primaryBlack'>
          <p>
            This privacy policy is for all Now Now Dispatch users and governs
            the privacy of its users who choose to use it.
            <span className='mt-5 block'>
              The policy sets out the different areas where user privacy is
              concerned and outlines the obligations & requirements of the
              users, the website and website owners. Furthermore, the way this
              website processes, stores and protects user data and information
              will also be detailed within this policy.
            </span>
          </p>

          <h2 className='font-semibold text-1xl text-redOuter mt-8'>Scope</h2>
          <p>
            This notice applies to users of Now Now Dispatch’s apps, websites,
            and all other services.{" "}
            <span className='block mt-5'>
              This notice describes how Now Now Dispatch and its affiliates
              collect and use data. This notice specifically applies to:
            </span>
          </p>
          <ul className='mt-5 ml-8'>
            <li className='list-dis'>
              Drivers/Riders: those who provide logistics services to customers
              individually via their Now Now Dispatch account.
            </li>
            <li className='list-dis'>
              Order recipients: those who request or receive products, goods,
              packages or items and services for delivery or pick-up via their
              Now Now Dispatch account. This includes those who use guest
              checkout features to access delivery or pick-up services without
              creating and/or logging into their account.
            </li>
            <li className='list-dis'>
              Guest users: those without a Now Now Dispatch account who receive
              logistics services ordered by other Now Now Dispatch account
              owners, including those who receive services arranged by Now Now
              Dispatch customers (collectively, “Enterprise Customers”); or by
              friends, family members or other individual account owners.
            </li>
            <li className='list-dis'>
              Owners: those who make their vehicle(s) available to Now Now
              Dispatch.
            </li>
          </ul>

          <p className='mt-5'>
            This notice also governs Now Now Dispatch’s other collections of
            data in connection with its services. For example, we may collect
            the contact information of owners or employees of restaurants,
            merchants or other affiliates on the Now Now Dispatch platforms;
            contact information of those who manage and use accounts owned by
            Enterprise Customers; or data of those who start but do not complete
            their applications to be drivers or delivery persons.
            <span className='block mt-5'>
              {" "}
              All those subject to this notice are referred to as “users” in
              this notice.
            </span>
            <span className='block mt-5'>
              Our privacy practices are subject to and shall in all respects be
              governed and enforced in accordance with Nigerian Laws.
            </span>
          </p>

          <h2 className='font-semibold text-2xl text-redOuter mt-8'>
            The Data We Collect
          </h2>
          <p>Now Now Dispatch collects data:</p>
          <ul className='list-disc ml-8'>
            <li>provided by users</li>
            <li>created during the use of our services</li>
            <li>from other sources</li>
          </ul>

          <p>
            Now Now Dispatch collects the following data from these sources:
          </p>
          <h3 className='font-semibold mt-6'>1. Data provided by users</h3>
          <p>This includes:</p>
          <ul className='list-disc ml-8'>
            <li>
              We collect data when users create or update their Now Now Dispatch
              accounts, or place orders via guest checkout features. This may
              include first and last name, email, phone number, login name and
              password, address, profile picture, payment or banking information
              (including related payment verification information), user
              settings (including accessibility settings), and loyalty program
              information for Now Now Dispatch partners. For drivers and riders,
              this also includes vehicle or insurance information and emergency
              contact information to provide services using the Now Now Dispatch
              apps.
            </li>
            <li>Background check information (drivers and riders)</li>
            <li>
              Identity verification documents and photos: This includes
              government issued identification such as driver’s license or
              passport (which may contain identification photos and numbers,
              date of birth, and gender), and user-submitted photos such as
              selfies and profile pictures.
            </li>
            <li>
              Demographic data: We collect demographic data such as birth
              date/age, gender or occupation when necessary to enable certain
              features or provide access to age-restricted products or services.
            </li>
            <li>
              User Content: We collect data including chat logs and call
              recordings when users contact Now Now Dispatch customer support,
              provide ratings or feedback for users, restaurants or merchants,
              products or other items, use features that enable users to upload
              content or submit recordings (including in-app audio recordings or
              dashboard cameras recordings), or otherwise contact Now Now
              Dispatch.
            </li>
          </ul>

          <h3 className='font-semibold mt-6'>
            2. Data created during the use of our services
          </h3>
          <ul className='list-disc ml-8'>
            <li>
              Location data (driver and delivery person): We collect precise and
              approximate location data from drivers’ and delivery persons’
              mobile devices when the Now Now Dispatch app is running.
            </li>
            <li>
              We collect precise and/or approximate location information from
              riders’ and order recipients’ mobile devices if they enable us to
              do so via their device settings.
            </li>
          </ul>

          <p>
            Now Now Dispatch collects such data from the time a ride or order is
            requested until it is finished, and any time the app is running in
            the foreground (app open and on-screen). Riders and order recipients
            may use the Now Now Dispatch app without enabling the collection of
            location data from their mobile devices. However, this may affect
            certain features in the Now Now Dispatch app. For example, a rider
            who has not enabled precise location data will have to manually
            enter their pickup address.
          </p>
          <ul className='list-disc ml-8'>
            <li>
              Transaction information: We collect transaction information
              related to the use of our services, including the type of services
              requested or provided; trip or order details (such as date and
              time, requested pick-up and drop-off addresses, distance traveled
              and items ordered, such as food, prescription drugs or other
              delivery items); and payment transaction information (such as a
              restaurant’s or merchant's name and location, amount charged, and
              payment method). We also associate a user’s name with that of
              anyone who uses their promotion code. This also includes
              information provided by users when placing their orders.
            </li>
            <li>
              Usage data: We collect data about how users interact with our
              services. This includes access dates and times, app features or
              pages viewed, browser type, app crashes and other system activity.
            </li>
            <li>
              Device data: We collect data about the devices used to access our
              services.
            </li>
            <li>
              Communications data: We collect data regarding communications
              between users that are enabled through Now Now Dispatch’s apps.
              This includes communication type (phone, text or in-app message),
              date/time, and content (including recordings of phone calls solely
              when users are notified of the recording in advance).
            </li>
          </ul>

          <h3 className='font-semibold mt-6'>3. Data from other sources</h3>
          <p>These include:</p>
          <ul className='list-disc ml-8'>
            <li>Users participating in our referral programs.</li>
            <li>
              Now Now Dispatch account owners who request services for or on
              behalf of other users.
            </li>
            <li>
              Users or others providing information in connection with claims or
              disputes.
            </li>
            <li>
              Now Now Dispatch business partners through which users create or
              access their Now Now Dispatch account, such as payment providers,
              social media services, or apps or websites that use Now Now
              Dispatch’s APIs or whose APIs Now Now Dispatch uses.
            </li>
            <li>
              Now Now Dispatch business partners in connection with debit or
              credit cards issued by a financial institution in partnership with
              Now Now Dispatch to the extent disclosed in the terms and
              conditions for the card.
            </li>
            <li>
              service providers who help us verify users’ identity, background
              information, and eligibility to work, detect fraud, and screen
              users in connection with sanctions, anti-money laundering, or
              know-your-customer requirements. insurance, vehicle, or financial
              services providers for drivers and/or delivery persons
            </li>
            <li>
              partner transportation companies (for drivers or delivery persons
              who use our services through an account associated with such a
              company)..
            </li>
            <li>publicly available sources.</li>
            <li>
              marketing partners and service providers, including banks in
              connection with cash-back programs, and data resellers
            </li>
            law enforcement officials, public health officials, and other
            government authorities.
          </ul>

          <h2 className='semi-bold text-2xl text-redOuter mt-8'>
            HOW WE USE DATA
          </h2>
          <p>
            Now Now Dispatch uses data to enable reliable and convenient
            transportation, delivery, and other products and services. We also
            use data:{" "}
          </p>
          <ul className='list-disc mt-3 ml-8'>
            <li>
              {" "}
              to enhance the safety and security of our users and services
            </li>
            <li>For customer support</li>
            <li>For research and development</li>
            <li>To enable communications between users</li>
            <li>To send marketing and non-marketing communications to users</li>
            <li>In connection with legal proceedings</li>
          </ul>

          <p className='mt-5'>Now Now Dispatch uses the data we collect:</p>

          <p className='mt-5'>
            1. To provide our services. Now Now Dispatch uses data to provide,
            personalize, maintain, and improve our services. This includes using
            data to:
            <ul className='list-disc'>
              <li> create/update accounts.</li>

              <li>
                enable transportation, delivery and other services/features,
                such as location data to navigate rider pick-ups and order
                drop-offs, calculate ETAs, and track the progress of rides or
                deliveries. o enabling features that involve data sharing, such
                as sharing driver first name and vehicle information with riders
                to facilitate pick-ups, or features that enable ETA sharing and
                fare splitting. o matching available drivers and riders to users
                requesting services, including based on personal data such as
                location and proximity to other users, user settings/preferences
                (such as preferred destinations), and non-personal data such as
                vehicle type requested.
              </li>
              <li>
                {" "}
                calculating prices and fares, including using location data and
                trip or order details (such as requested pick-up and drop-off
                addresses). We may also consider non-personal data or factors,
                including date and time, estimated distance and time, minimum
                base fares, tolls, taxes and fees, and surge pricing.
              </li>
              <li>
                process payments, and enable payment and e-money products.
              </li>
              <li>personalize users’ accounts.</li>
              <li>
                {" "}
                facilitate insurance, vehicle, invoicing, or financing
                solutions.
              </li>
              <li>
                {" "}
                provide users with trip or delivery updates, generate receipts,
                and inform them of changes to our terms, services, or policies.
              </li>
              <li>
                {" "}
                perform necessary operations to maintain our services, including
                to troubleshoot software bugs and operational problems. Now Now
                Dispatch performs the above activities because they are
                necessary to fulfil the terms of our agreements with users, are
                compatible with such uses, or are necessary for its user’s
                legitimate interests.
              </li>
            </ul>
            <p className='mt-5'>
              {" "}
              2. Safety, fraud protection and security. We use data to help
              maintain the safety, security, and integrity of our services and
              users. This includes:
            </p>{" "}
            <ul className='list-disc'>
              <li>
                verifying users' accounts, identity or compliance with safety
                requirements. We also require verification of user identity
                and/or age to use payment methods such as cash and receive
                deliveries. We process and compare user profile pictures,
                government-issued identification photos and numbers, or other
                user-submitted photographs to perform this verification,
                including in some regions through the use of facial recognition
                technology. We also use such technology to prevent fraudulent
                use of identification photos or to prevent users from creating
                multiple accounts.
              </li>
            </ul>
            <p className='mt-5'>
              We also use facial recognition technology to prevent fraudulent
              use of Now Now Dispatch accounts by those other than the account
              owner. We may use this and data from delivery persons’ devices to
              verify the type of vehicles they use to provide deliveries.{" "}
            </p>
            <ul className='list-disc'>
              <li>
                {" "}
                using customer service information (including reports of safety
                incidents), device data (e.g., to detect speeding or harsh
                braking/acceleration), transaction, and usage data to identify
                potentially unsafe drivers and driving. This can lead to drivers
                and delivery persons receiving messages encouraging safer
                driving, and/or account deactivation following human review.
              </li>
              <li>
                using account, device, location, usage, transaction, wireless
                carrier, and other data, including communications between users
                and metadata, to prevent, detect, and combat fraud, including by
                guest users.
              </li>

              <li>
                {" "}
                using reported incidents, user ratings, and other feedback to
                encourage the safe use of Now Now Dispatch’s platform and
                compliance with our terms and as grounds for deactivating users
                with low ratings.
              </li>
              <li>
                using driver data (such as past trip information and reported
                incident rates) and rider data (such as account information,
                cancellation and reported incident rates, current pick-up and
                drop-off location, past trip information, and rating
                information) to predict and help avoid pairings of users that
                may result in increased risk of conflict. We also avoid pairings
                where one user has previously given the other a low (e.g., 1
                star) rating.
              </li>

              <li>
                {" "}
                using driver data (such as past trip information and reported
                incident rates) and rider data (such as account information,
                cancellation and reported incident rates, current pick-up and
                drop-off location, past trip information, and rating
                information) to predict and help avoid pairings of users that
                may result in increased risk of conflict. We also avoid pairings
                where one user has previously given the other a low (e.g., 1
                star) rating.
              </li>
              <li>
                using location, phone number, user name, vehicle details and
                other relevant information to provide live support from safety
                experts during trips or deliveries. Now Now Dispatch performs
                the above activities because they are necessary to fulfil the
                terms of our agreements with users, and/or for purposes of the
                legitimate safety and security interests of Uber, our users and
                members of the general public.
              </li>
            </ul>
            <p>
              3. Customer support. We use the information we collect (which may
              include call recordings, chat logs, in-app audio recordings and
              dashcam footage) to provide customer support, including to
              investigate and address user concerns and to monitor and improve
              our customer support responses and processes. Now Now Dispatch
              performs the above activities because they are necessary to fulfil
              the terms of our agreements with users or for purposes of Now Now
              Dispatch’s legitimate interests in monitoring and improving its
              customer support services.
            </p>
            <p>
              4. Research and development. We use data for analysis, machine
              learning, product development, research, and testing. This helps
              us make our services more convenient and easy to use, enhance the
              safety and security of our services, and develop new services and
              features.
            </p>
            <p>
              5. Enabling communications between users. For example, a driver
              may message or call a rider to confirm a pick-up location, a rider
              may call a driver to retrieve a lost item, or a restaurant or
              delivery person may contact an order recipient with information
              about their order.
            </p>
            <p>
              6. Marketing and Advertising. Now Now Dispatch uses data to market
              its services. We specifically use the account, approximate
              location, device and usage data, preferred language, and trip and
              order history to provide ads and marketing communications that are
              personalized based on users’ observed or inferred location,
              interests and characteristics.
              <p>This includes using this data to:</p>
              <ul className='list-disc'>
                <li>
                  {" "}
                  send emails, text messages, push notifications, and in-app
                  messages or other communications marketing services, features,
                  offers, promotions, sweepstakes, news and events. For example,
                  we may send push notifications suggesting a user’s favourite
                  destinations or merchants, or in-app messages offering
                  discounts or promo for products or merchants similar to those
                  a user has previously ordered.
                </li>
                <li>
                  display personalized advertising on third-party apps or
                  websites.{" "}
                </li>
                <li>
                  {" "}
                  display third-party advertising in Now Now Dispatch’s apps or
                  in connection with our services. For example, we display ads
                  for restaurants or merchants that are available on Now Now
                  Dispatch’s apps. These ads may include recommendations that
                  are personalized based on users’ location and order histories.
                </li>
              </ul>
              <p>
                We also provide ads that are personalized based on data about
                users’ current trip or delivery request, including time of
                request and services requested. For example, if a user requests
                a trip to a supermarket, we may display in-app ads for
                third-party products that may be available at that supermarket.
              </p>
            </p>
          </p>

          <h3 className='semi-bold text-2xl text-redOuter mt-8'>THE WEBSITE</h3>
          <p className='mt-5'>
            This website and its owners take a proactive approach to user
            privacy and ensure the necessary steps are taken to protect the
            privacy of its users throughout their visiting experience. This
            website complies with all Nigerian laws and requirements for user
            privacy.
          </p>

          <h3 className='semi-bold text-2xl text-redOuter mt-8'>
            USE OF COOKIES
          </h3>
          <p className='mt-5'>
            This website uses cookies to better the user’s experience while
            visiting the website. Where applicable this website uses a cookie
            control system allowing the user on their first visit to the website
            to allow or disallow the use of cookies on their computer/device.
            This complies with recent legislation requirements for websites to
            obtain explicit consent from users before leaving behind or reading
            files such as cookies on a user’s computer/device. Cookies are small
            files saved to the user’s device’s hard drive that track, save and
            store information about the user’s interactions and usage of the
            website. This allows the website, through its server to provide the
            users with a tailored experience within this website. Users are
            advised that if they wish to deny the use and saving of cookies from
            this website onto their device’s hard drive they should take
            necessary steps within their web browser’s security settings to
            block all cookies from this website and its external serving
            vendors. This website uses tracking software to monitor its visitors
            to better understand how they use it. The software will save a
            cookie to your device’s hard drive in order to track and monitor
            your engagement and usage of the website, but will not store, save
            or collect personal information. Other cookies may be stored on your
            device’s hard drive by external vendors when this website uses
            referral programs, sponsored links or adverts. Such cookies are used
            for conversion and referral tracking. No personal information is
            stored, saved or collected.
          </p>
          <h3 className='semi-bold text-2xl text-redOuter mt-8'>
            CONTACT & COMMUNICATION
          </h3>
          <p className='mt-5'>
            Users contacting this website and/or its owners do so at their own
            discretion and provide any such personal details requested at their
            own risk. Your personal information is kept private and stored
            securely until a time it is no longer required or has no use, as
            detailed in the Data Protection Act 2004. Every effort has been made
            to ensure a secure form-to-email submission process. However, users
            are advised to use this process at their own risk. This website and
            its owners use any information submitted to provide you with further
            information about the products/services they offer or to assist you
            in answering any questions or queries you may have submitted. This
            includes using your details to subscribe you to any email newsletter
            program the website operates but only if this was made clear to you
            and your express permission was granted when submitting any form to
            the email process. Or whereby you the consumer have previously
            purchased from or enquired about purchasing from the company a
            product or service that the email newsletter relates to. This is by
            no means an entire list of your user rights in regard to receiving
            email marketing material. Your details are not passed on to any
            third parties.
          </p>
          <h3 className='semi-bold text-2xl text-redOuter mt-8'>
            EMAIL NEWSLETTER
          </h3>
          <p className='mt-5'>
            This website operates an email newsletter program, used to inform
            subscribers about products and services supplied by this website.
            Users can subscribe through an online automated process should they
            wish to do so but do so at their own discretion. Some subscriptions
            may be manually processed through a prior agreement with the user.
            Subscriptions are taken in compliance with Nigerian Spam Laws
            detailed in the Privacy and Electronic Communications Regulations
            2004. All personal details relating to subscriptions are held
            securely and in accordance with the Data Protection Act 2004. No
            personal details are passed on to third parties or shared with
            companies/people outside of the company that operates this website.
            Under the Data Protection Act 2004, you may request a copy of
            personal information held about you by this website’s email
            newsletter program. A small fee will be payable. If you would like a
            copy of the information held on you please write to the business
            address at the bottom of this policy. Email marketing campaigns
            published by this website or its owners may contain tracking
            facilities within the actual email. Subscriber activity is tracked
            and stored in a database for future analysis and evaluation. Such
            tracked activity may include; the opening of emails, forwarding of
            emails, the clicking of links within the email content, times, dates
            and frequency of activity [this is by no means a comprehensive
            list]. This information is used to refine future email campaigns and
            supply the user with more relevant content based on their activity.
            In compliance with Nigerian Spam Laws and the Privacy and Electronic
            Communications Regulations 2004, subscribers are allowed to
            unsubscribe at any time through an automated system. This process is
            detailed at the footer of each email campaign. If an automated
            un-subscription system is unavailable clear instructions on how to
            un-subscribe will be detailed instead.
          </p>
          <h3 className='semi-bold text-2xl text-redOuter mt-8'>
            EXTERNAL LINKS
          </h3>
          <p className='mt-5'>
            Although this website strives to include only high-quality, safe,
            and relevant external links, users are urged to exercise caution
            before clicking on any external web links mentioned throughout this
            website. (External links are clickable text/banner/image links to
            other websites, similar to; Folded Book Art or Used Model Trains For
            Sale.) The owners of this website cannot guarantee or verify the
            contents of any externally linked website. Users should therefore
            note they click on external links at their own risk and this website
            and its owners cannot be held liable for any damages or implications
            caused by visiting any external links mentioned.
          </p>
          <h3 className='semi-bold text-2xl text-redOuter mt-8'>
            ADVERTS AND SPONSORED LINKS
          </h3>
          <p className='mt-5'>
            This website may contain sponsored links and adverts. They may be
            served through our advertising partners, who may have detailed
            privacy policies relating directly to the adverts they serve.
            Clicking on any such adverts will send you to the advertisers’
            website through a referral program which may use cookies and will
            track the number of referrals sent from this website. This may
            include the use of cookies which may in turn be saved on your
            device’s hard drive. Users should therefore note they click on
            sponsored external links at their own risk and this website and its
            owners cannot be held liable for any damages or implications caused
            by visiting any external links mentioned.
          </p>
          <h3 className='semi-bold text-2xl text-redOuter mt-8'>
            SOCIAL MEDIA PLATFORMS
          </h3>
          <p className='mt-5'>
            Communication, engagement and actions taken through external social
            media platforms that this website and its owners participate in are
            custom to the terms and conditions as well as the privacy policies
            held with each social media platform respectively. Users are advised
            to use social media platforms wisely and engage with them with due
            care and caution regarding their privacy and personal details.
            Neither this website nor its owners will ever ask for personal or
            sensitive information through social media platforms and encourage
            users wishing to discuss sensitive details to contact them through
            primary communication channels such as by telephone or email. This
            website may use social sharing buttons which help share web content
            directly from web pages to the social media platform in question.
            Users are advised before using such social sharing buttons that they
            do so at their own discretion and note that the social media
            platform may track and save your request to share a web page
            respectively through your social media platform account.
          </p>
          <h3 className='semi-bold text-2xl text-redOuter mt-8'>
            SHORTENED LINKS IN SOCIAL MEDIA
          </h3>
          <p className='mt-5'>
            This website and its owners through their social media platform
            accounts may share web links to relevant web pages. By default, some
            social media platforms shorten lengthy URLs. Users are advised to
            take caution and good judgment before clicking any shortened URLs
            published on social media platforms by this website and its owners.
            Despite the best efforts to ensure only genuine URLs are published
            many social media platforms are prone to spam and hacking and
            therefore this website and its owners cannot be held liable for any
            damages or implications caused by visiting any shortened links.
          </p>
          <h3 className='semi-bold text-2xl text-redOuter mt-8'>
            UPDATES TO THIS PRIVACY POLICY
          </h3>
          <p className='mt-5'>
            We may occasionally update this notice. If we make significant
            changes, we will notify users in advance of the changes through the
            Now Now Dispatch app or other means, such as email. We encourage
            users to periodically review this notice for the latest information
            on our privacy practices. Use of our services after an update
            constitutes consent to the updated notice to the extent permitted by
            law.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
