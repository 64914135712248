import nowpix from "./nowpix.svg"; //
import instagramLogo from "./instagramLogo.svg"; //
import facebook from "./facebook.svg"; //
import twitter from "./twitter.svg"; //
import linkedin from "./linkedin.svg"; //
import playstore from "./playstore.svg"; //
import apple from "./apple.svg"; //
import HeroImage from "./HeroImage.webp"; //
import downloadApp from "./downloadApp.svg"; //
import setAccount from "./setAccount.webp"; //
import getOrder from "./getOrder.svg"; //
import vendor from "./vendor.webp"; //
import riderpix from "./riderpix.webp"; //
import Arrow1 from "./Arrow1.svg"; //
import dashboard1 from "./RiderDashboard.png"; //
import removebg from "./removebg.svg";
import boss from "./boss.svg";
import card from "./card.svg";
import riderduck from "./riderduck.svg"; //
import setUp from "./setUp.svg"; //
import riderUp from "./riderUp.svg";
import riderOder from "./riderOder.svg"; //
import downloadApp2 from "./downloadApp2.svg"; //
import contactpix from "./contactpix.png"; //
import mispix from "./mispix.svg"; //
import vispix from "./vispix.svg"; //
import closeBtn from "./closeBtn.svg";
import aboutpix from "./aboutpix.svg";
import nownow1 from "./nownow-1.jpeg";
import nownow2 from "./nownow-2.jpeg";
import nownow3 from "./nownow-3.jpeg";
import nownow4 from "./nownow-4.jpeg";
import nownow5 from "./nownow-5.jpeg";
import nownow6 from "./nownow-6.jpeg";
import nownow7 from "./nownow-7.jpeg";
import rider from "./rider.webp";
import delivery from "./delivery.webp";
import supermarket from "./supermarket.webp";
import woman from "./woman.webp";
import riders from "./riders.webp";

export const images = {
  riders,
  woman,
  rider,
  delivery,
  nownow1,
  nownow2,
  nownow3,
  nownow4,
  nownow5,
  nownow6,
  nownow7,
  aboutpix,
  closeBtn,
  nowpix,
  instagramLogo,
  facebook,
  twitter,
  linkedin,
  playstore,
  HeroImage,
  apple,
  downloadApp,
  setAccount,
  getOrder,
  riderpix,
  vendor,
  Arrow1,
  dashboard1,
  removebg,
  boss,
  card,
  riderOder,
  riderUp,
  setUp,
  riderduck,
  downloadApp2,
  contactpix,
  supermarket,
  mispix,
  vispix,
};
