import React from "react";
import NavBar from "../components/NavBar";
import { ErrorMessage, Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { images } from "../Assets";

import Footer from "../components/Footer";

const ContactUs = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    console.log(values);

    setSubmitting(false);
  };

  const handleInputChange = (e, setFieldValue) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  return (
    <div>
      <NavBar />
      <div>
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, setFieldValue, resetForm }) => (
            <Form>
              <div className=''>
                <div className='md:flex items-center justify-between pt-20'>
                  <div className='w-[45%] hidden md:block p-10'>
                    <img
                      src={images.contactpix}
                      className='w-full h-auto rounded-lg'
                      alt='contactUs'
                    />
                  </div>

                  <div className='w-full md:w-[45%] px-10 mt-10 md:mt-16 gap-8 mb-10'>
                    <div className='text-center md:text-left w-full md:w-[34rem]'>
                      <p className='font-bold text-4xl text-primaryBlack'>
                        Contact Us
                      </p>
                      <p className='font-normal text-base text-primaryBlack leading-7 mt-2'>
                        We value your feedback and strive to provide the best
                        possible experience for our customers. If you have any
                        suggestions or comments, please let us know!
                      </p>
                    </div>

                    <div className='mt-10'>
                      <div className='flex flex-wrap md:flex-nowrap mb-6'>
                        <div className='flex flex-col w-full md:w-1/2 px-3'>
                          <label
                            htmlFor='firstName'
                            className='font-medium mb-1 text-xs'
                          >
                            First Name
                          </label>
                          <Field
                            id='firstName'
                            name='firstName'
                            type='text'
                            placeholder='Enter'
                            className='bg-transparent border border-[#C2C7D6] py-2 px-3 rounded-md outline-none w-full'
                            onChange={(e) =>
                              handleInputChange(e, setFieldValue)
                            }
                          />
                          {errors.firstName && touched.firstName && (
                            <p className='text-xs text-red'>
                              <ErrorMessage name='firstName' />
                            </p>
                          )}
                        </div>

                        <div className='flex flex-col w-full md:w-1/2 px-3'>
                          <label
                            htmlFor='lastName'
                            className='font-medium mb-1 text-xs'
                          >
                            Last Name
                          </label>
                          <Field
                            id='lastName'
                            name='lastName'
                            type='text'
                            placeholder='Enter'
                            className='bg-transparent border border-[#C2C7D6] py-2 px-3 rounded-md outline-none w-full'
                            onChange={(e) =>
                              handleInputChange(e, setFieldValue)
                            }
                          />
                          {errors.lastName && touched.lastName && (
                            <p className='text-xs text-red'>
                              <ErrorMessage name='lastName' />
                            </p>
                          )}
                        </div>
                      </div>

                      <div className='flex flex-col px-3 mx-auto w-full'>
                        <label
                          htmlFor='email'
                          className='font-medium mb-1 text-xs'
                        >
                          Email
                        </label>
                        <Field
                          id='email'
                          name='email'
                          type='text'
                          placeholder='Enter'
                          className='bg-transparent border border-[#C2C7D6] py-2 px-3 rounded-md outline-none w-full'
                          onChange={(e) => handleInputChange(e, setFieldValue)}
                        />
                        {errors.email && touched.email && (
                          <p className='text-xs text-red'>
                            <ErrorMessage name='email' />
                          </p>
                        )}
                      </div>

                      <div className='flex flex-col px-3 mx-auto mt-5 w-full'>
                        <label
                          htmlFor='message'
                          className='font-medium mb-1 text-xs'
                        >
                          Message
                        </label>
                        <Field
                          id='message'
                          name='message'
                          as='textarea'
                          placeholder='Enter'
                          className='bg-transparent border border-[#C2C7D6] py-2 px-3 rounded-md outline-none w-full md:h-32'
                          onChange={(e) => handleInputChange(e, setFieldValue)}
                        />
                        {errors.message && touched.message && (
                          <p className='text-xs text-red'>
                            <ErrorMessage name='message' />
                          </p>
                        )}
                      </div>
                    </div>

                    <div className='mt-8 px-3 mx-auto w-full text-left'>
                      <button
                        type='submit'
                        className='bg-primaryGreen text-white py-2 px-6 rounded-md'
                        onClick={resetForm}
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
